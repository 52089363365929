<script lang="ts" setup></script>

<template>
  <div
    :class="[
      'absolute flex flex-col justify-center items-center w-full h-full z-10',
      'backdrop-blur-md bg-white/40',
      'text-brand-navy-700'
    ]"
  >
    <NuxtIcon name="statuses/upcoming" filled class="lock-icon" />
    <p class="text-center text-lg leading-6 font-medium">
      Go premium <br />
      to unlock this feature
    </p>
  </div>
</template>

<style lang="scss" scoped>
:deep(.lock-icon) {
  svg {
    @apply w-[30px] h-[30px] mb-[5px] fill-white;

    & > rect {
      @apply fill-current;
    }

    & > g path {
      @apply fill-inherit;
    }
  }
}
</style>
